.otherProduct_global-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #F9F9F9;
    border-radius: 8px;
    width: 350px;
    height: 265px;
    font-family: sans-serif;
    padding: 10px;
    gap: 30px;
}

.otherProduct-img{
    height: 250px;
}

.otherProduct_text_btn-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 70%;
}

.otherProduct_title-container{
    display: flex;
    flex-direction: column;
}

.otherProduct-title{
    font-size: 25px;
    color: #CE886D;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 10px;
}

.otherProduct-subtitle{
    font-size: 16px;
    color: #6D6869;
    margin: 0;
}

.otherProduct-btn{
    border: none;
    border-radius: 25px;
    width: 150px;
    height: 30px;
    background: #F1BCA8;
    text-transform: uppercase;
    cursor: pointer;
}