body {
    margin: 0;
    overflow-x: hidden;
}

.mailto,
.telto {
    text-decoration: none;
}

.header-svg-mail svg {
    margin-left: 20px;
    margin-right: 5px;
    fill: rgb(255, 255, 255);
}

.header-div-container {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);
}

/* PHONE */
.header-div-phone {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    background-color: #000000;
}

.header-img-phone {
    width: 20px;
    height: 20px;
}

.header-p-phone {
    font-family: 'Poppins', sans-serif;
    color: #fff;
    font-size: 12px;
}

/* MENU */
.header-div-menu {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    gap: 50px;
    width: 90vw;
}

.header_business-btn {
    position: absolute;
    right: 10px;
    background-color: #F1BCA8;
    border: none;
    border-radius: 25px;
    height: 35px;
    width: 220px;
    text-transform: uppercase;
    cursor: pointer;
}

.header-item {
    text-transform: uppercase;
    font-family: sans-serif;
    cursor: pointer;
}

.header_dropdown-container {
    display: flex;
    align-items: center;
    gap: 5px;
}

.header-p-menu {
    color: #111426;
    text-align: center;
    font-family: sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.header-img-menu {
    height: 58px;
    margin-left: 20px;
    margin-right: 20px;
}

.svg-header-menu {
    display: none;
    width: 35px;
    height: 22px;
    position: absolute;
    right: 7%;
    cursor: pointer;
}

.menu-burger {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    height: 95vh;
}

.svg-close-menu {
    position: absolute;
    right: 15px;
    top: 100px;
    cursor: pointer;
}

.item-menu {
    font-size: 16px;
    color: #111426;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
    padding: 15px;
}

.none {
    display: none;
}

.container-menu-link {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 10vh
}

.header-menu-bottom {
    position: absolute;
    bottom: 0;
    background-color: #121628;
    height: 35vh;
    width: 100vw;
}

.img-menu-liposome {
    z-index: 1;
    width: 265px;
    height: auto;
    position: absolute;
    bottom: 0;
    margin-bottom: 28vh;
}

.header-menu-bottom {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.text-menu-bottom {
    color: white;
    margin: 10px;
    font-family: 'Poppins', sans-serif;
}

.logoOcre {
    width: 80%;
    margin-left: 20px;
    margin-right: 20px;
}

.dropdown-menu {
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    z-index: 1000;
    position: absolute;
    font-family: sans-serif;
    padding: 20px;
}

.dropdown-item{
    margin-bottom: 10px;
    cursor: pointer;
}

@media screen and (max-width: 1350px) {
    .header-div-menu {
        width: 100vw;
    }

    .header_business-btn {
        margin-right: 20px;
    }
}

/* version mobile header */
@media screen and (max-width: 1050px) {
    .header-p-menu {
        display: flex;
    }

    .header-div-menu {
        height: 100px;
    }

    .header-img-phone {
        height: 13px;
        width: 13px;
        margin-bottom: 2px;
    }

    .svg-header-menu {
        display: block;
    }

    .header-item {
        display: none;
    }

    .header_dropdown-container {
        display: none;
    }

    .header_business-btn {
        display: none;
    }
}

@media screen and (max-width: 600px) and (min-width:300px) {
    .header-div-menu {
        width: 100%;
    }

    header-p-menu {
        font-size: 10px;
    }
}