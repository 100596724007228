@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;400&family=Montserrat:wght@200&family=Poppins&display=swap');

.container-all {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.container-product-all {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 15%;
    margin-top: 4%;
    align-items: center;
}

.container-product-category {
    display: flex;
    flex-wrap: wrap;
    width: 70vw;
    max-width: 1500px;
}

/*this ensure there will be 4 items per row*/
.item_in_product-category {
    display: flex;
    flex-basis: 25%;
}

.text_in_product_category {
    font-family: 'poppins';
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.text_in_product_category .text {
    margin: 0;
}

.text_in_product_category .small-text {
    font-size: 16px;
    color: #6D6869;
    padding: 0;
}

/* version telephone */
@media (max-width: 441px) {
    .container-product-all {
        margin: 0 3%;
    }

    .container-product-category {
        margin-bottom: 20%;
    }

    /*only 2 items per row on phone version*/
    .item_in_product-category {
        flex-basis: 50%;
    }

    /*text take a whole line on phone version*/
    .text_in_product_category {
        flex-basis: 100%;
    }
}