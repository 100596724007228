.routine_global-container {
    width: 100%;
    max-width: 1900px;
    display: flex;
    flex-direction: column;
    font-family: sans-serif;
    margin: 0 auto;
}

.routine_intro-container {
    margin: 0 auto;
    margin-top: 70px;
    width: 60vw;
    max-width: 1200px;
}

.routine_intro-title {
    text-transform: uppercase;
    font-size: 42px;
    margin: 0;
    margin-bottom: 30px;
}

.horizontal-bar {
    height: 4px;
    width: 80px;
    background-color: black;
    margin-bottom: 30px;
}

.routine_intro-subtitle {
    font-weight: bold;
}

.routine_intro_btn-container {
    display: flex;
    gap: 50px;
    align-items: center;
    justify-content: center;
}

.routiner_intro-btn {
    width: 200px;
    text-transform: uppercase;
    padding: 10px;
    border: none;
    border-radius: 15px;
    background-color: #CE886D;
    cursor: pointer;
    height: 30px;
}

.routine_intro-text {
    color: #6D6869;
    margin-top: 30px;
    text-align: justify;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25.6px;
    margin-bottom: 70px;
}

.pink {
    background-color: #F1BCA8;
}

.routineProduct_global-container{
    display: flex;
    gap: 50px;
    align-items: center;
    justify-content: center;
    margin-bottom: 120px;
}

.routineProduct_items{
    display: flex;
    justify-content: center;
    width: 300px;
    height: 500px;
}

.routineProduct-list{
    list-style: none;
    padding: 0;
}

.routineProduct_category{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.routineProduct_title_product-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.routineProduct_product-index{
    margin: 0;
    color: #CE886D;
    text-align: center;
    font-weight: bold;
    font-size: 32px;
}

.routineProduct_product-title{
    margin: 0px;
    color: #CE886D;
    margin-bottom: 10px;
    text-transform: uppercase;
}

.routineProduct_horizontal-bar{
    height: 2px;
    width: 50px;
    background-color: #CE886D;
    margin-bottom: 50px;
}

.routineProduct_product_name{
    font-weight: 500;
    line-height: 20px;
    text-transform: uppercase;
    font-size: 16px;
    text-align: center;
}

.routinePorudct_product-ingredients{
    text-align: center;
    font-size: 14px;
    color: #6D6869;
}

.routineProduct-img{
    height: 350px;
    width: 170px;
}