.HomeB2b_global-container{
    max-width: 1900px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.otherproduct-container{
    padding: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 50px;
}

.otherproduct_container_title-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.otherproduct_container_title-container p, .otherproduct_container_title-container h3{
    margin: 0;
    font-family: sans-serif;
}

.otherproduct_container_title-container h3{
    margin-bottom: 10px;
    text-transform: uppercase;
}